<template>
  <div class="outer-page">
    <div v-for="item in 4"></div>
    <div class="iconfont icon-guanbi1 guanbi"></div>
    <div class="echarts-detail">
      <div class="title">
        <span style="line-height: 34px; color: #fff"
          >八区第二阶梯土壤墒情采集点-变化曲线</span
        >
        <Select style="width: 150px" clearable>
          <Option value="1">土壤PH</Option>
        </Select>
      </div>
      <div class="echarts-detail" ref="soilCharts"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
/deep/ .ivu-select-dropdown {
  background: rgba(23, 34, 60, 0.6);
}
/deep/ .ivu-select-item {
  color: #fff;
}
/deep/ .ivu-select-item:hover {
  background: rgba(23, 34, 60, 0.4);
}
/deep/ .ivu-select-item-focus {
  background: rgba(23, 34, 60, 0.6);
}
/deep/ .ivu-select-selection {
  background: rgba(23, 34, 60, 0.6);
  border: 1px solid rgba(23, 34, 60, 0.6);
}
/deep/.ivu-select-visible .ivu-select-selection {
  border-color: rgba(23, 34, 60, 0.6);
  outline: 0;
  box-shadow: 0 0 0 2px rgb(23 34 60 / 20%);
}
/deep/ .ivu-select {
  color: #fff;
}
/deep/ .green-theme .ivu-select-item-selected,
.green-theme .ivu-select-item-selected:hover {
  color: #fff;
}
/deep/ .ivu-select-selection:hover {
  border-color: rgba(23, 34, 60, 0.6);
}
.outer-page {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: rgba(23, 34, 60, 0.6);
  position: relative;
  div {
    &:nth-child(1) {
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 10px;
        position: absolute;
        top: 0;
        left: 0;
        border-top: 2px solid #00fff7;
        border-left: 2px solid #00fff7;
      }
    }
    &:nth-child(2) {
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 10px;
        position: absolute;
        top: 0;
        right: 0;
        border-top: 2px solid #00fff7;
        border-right: 2px solid #00fff7;
      }
    }
    &:nth-child(3) {
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-bottom: 2px solid #00fff7;
        border-right: 2px solid #00fff7;
      }
    }
    &:nth-child(4) {
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 10px;
        position: absolute;
        left: 0;
        bottom: 0;
        border-left: 2px solid #00fff7;
        border-bottom: 2px solid #00fff7;
      }
    }
  }
  .guanbi {
    color: #ececec;
    cursor: pointer;
    font-size: 10px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 6px;
    &:hover {
      color: #fff;
    }
  }
  .echarts-detail {
    width: 100%;
    height: 100%;
    .title {
      margin-top: 6px;
      height: 34px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>